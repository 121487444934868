import { useState } from "react";
import "../styles/widget-container.scss";
import { ChatThreadToolbar } from "./ChatThreadToolbar";
import { Chat } from "./Chat";

const WidgetContainer = ({children}) => {
    const [open, setOpen] = useState(false);

    if (open) {
        return (            
            <div className="widget-container-lw">
                <Chat onClickTopBar={()=>setOpen(false)}/>
            </div>
        );
    }
    else {
        return (
            <div className="widget-container-placeholder-lw">
                <ChatThreadToolbar  onClick={()=>setOpen(true)} />
            </div>
        )
    }
};

export { WidgetContainer };
