import { ENDPOINT } from "../constants";

export const startConversation = async (params) => {
  try {
    const res = await fetch(`${ENDPOINT}/startConversation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...params }),
    });

    const { data } = await res.json();
    const uuid = data.uuid;

    const messages = await sendMessage(uuid, "");

    return { uuid, messages };
  } catch (e) {
    console.error(e);
  }
};

export const getConversation = async (uuid) => {
  try {
    const res = await fetch(`${ENDPOINT}/getConversation?uuid=${uuid}`, {
      method: "POST",
    });

    const { data } = await res.json();
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const sendMessage = async (uuid, text) => {
  try {
    const res = await fetch(`${ENDPOINT}/sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid, text }),
    });

    const { data } = await res.json();

    return data.messages.filter((message) => message.type !== "hidden");
  } catch (e) {
    console.error(e);
  }
};

 // Car versions


export const startConversationCar = async (params) => {
  try {
    const res = await fetch(`${ENDPOINT}/car/startConversation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...params }),
    });

    const { data } = await res.json();
    const uuid = data.uuid;

    const messages = await sendMessageCar(uuid, "");

    return { uuid, messages };
  } catch (e) {
    console.error(e);
  }
};


export const getConversationCar = async (uuid) => {
  try {
    const res = await fetch(`${ENDPOINT}/car/getConversation?uuid=${uuid}`, {
      method: "POST",
    });

    const { data } = await res.json();
    return data;
  } catch (e) {
    console.error(e);
  }
};


export const sendMessageCar = async (uuid, text) => {
  try {
    const res = await fetch(`${ENDPOINT}/car/sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uuid, text }),
    });

    const { data } = await res.json();

    return data.messages.filter((message) => message.type !== "hidden");
  } catch (e) {
    console.error(e);
  }
};