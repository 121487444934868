import Send01Icon from "@untitled-ui/icons-react/build/esm/Send01";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import { useRef, useState } from "react";

const ChatMessageAdd = ({ disabled = false, onSend }) => {
  const inputRef = useRef(null);
  const [body, setBody] = useState("");

  const handleChange = (event) => {
    setBody(event.target.value);
  };

  const handleSend = async () => {
    if (body.length === 0) return;

    await onSend(body);
    setBody("");
    inputRef.current.focus();
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter") {
      handleSend();
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      backgroundColor="#FFF"
      spacing={2}
      sx={{
        px: 3,
        py: 1,
      }}
    >
      <OutlinedInput
        inputRef={inputRef}
        disabled={disabled}
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder="Stel een vraag of geef informatie"
        size="small"
        value={body}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          m: -2,
          ml: 2,
        }}
      >
        <Tooltip title="Send">
          <Box sx={{ m: 1 }}>
            <IconButton
              color="primary"
              disabled={!body || disabled}
              sx={{
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={handleSend}
            >
              <SvgIcon>
                <Send01Icon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export { ChatMessageAdd };
