import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import "../styles/product-card.scss";
import Button from "@mui/material/Button";

const ProductCard = ({ product }) => {
    const [expanded, setExpand] = useState(false);

  return (
   <div className="product-card-lw">
      <Box position="relative" height="150px" width="100%" marginTop={1} marginBottom={1}>
        <img
          width={200}
          alt={product.title}
          src={product.media[0]}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography variant="h6" marginTop={1}>{product.title}</Typography>
      <Typography fontWeight={700}  variant="subtitle2">
        {/* Prijs: €{product.priceLowest.amount},-  Uncommenten voor camera versie*/}
        Prijs: €{product.productDetails.price},-
      </Typography>
      <Typography
        variant="subtitle2"
        marginBottom={1}
        marginTop={1}
       
      >
        <div onClick={()=>setExpand(true)} className={expanded?'product-card-lw__description product-card-lw__description--expanded':'product-card-lw__description product-card-lw__description--short'}>
            <div dangerouslySetInnerHTML={{__html: product.description}}/>
        </div>
        </Typography>

    <Button
        //variant="outlined"
        type="button"
        target="_blank"
        style={{
          backgroundColor: "#58d686",
          color: "#262c48",
          width: "100%",
        }}
        // href={product.priceLowest.link} Uncommenten voor camera versie
        href={product.link}
      >
        Bekijk product
      </Button>

  </div>
  );
};

export { ProductCard };
