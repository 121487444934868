import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { ChatMessageBodyText } from "./ChatMessageBodyText";
import { ChatMessageBodyProducts } from "./ChatMessageBodyProducts";
import { ChatMessageBodyProductAdvice } from "./ChatMessageBodyProductAdvice";
import { ChatMessageBodyLoading } from "./ChatMessageBodyLoading";
import { ChatMessageBodyProductInformation } from "./ChatMessageBodyProductInformation";

const ChatMessage = ({
  text,
  options,
  position,
  type,
  onSend,
  product_data,
}) => {
  const getMessageBody = () => {
    switch (type) {
      case "loading":
        return <ChatMessageBodyLoading />;
      case "productAdvice":
        return (
          <ChatMessageBodyProductAdvice
            text={text}
            options={options}
            product={product_data}
            sendMessage={(message) => onSend(message, true)}
          />
        );
      case "productList":
        return (
          <ChatMessageBodyProducts 
            text={text} 
            products={product_data} 
            options={options} 
            sendMessage={(message) => onSend(message, true)}
            />
        );
        case "productInformation":
          return (
            <ChatMessageBodyProductInformation
            position={position}
            text={text}
            options={options}
            onSend={onSend}
            />
          );
      default:
        return (
          <ChatMessageBodyText
            position={position}
            text={text}
            options={options}
            onSend={onSend}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: position === "right" ? "flex-end" : "flex-start",
      }}
    >
      <Stack
        alignItems="flex-start"
        direction={position === "right" ? "row-reverse" : "row"}
        spacing={2}
        sx={{
          maxWidth: 500,
          ml: position === "right" ? "auto" : 0,
          mr: position === "left" ? "auto" : 0,
        }}
      >
        {position === "left" && (
          <Avatar
            src="/avatar.png"
            sx={{
              height: 32,
              width: 32,
            }}
          />
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Card
            sx={{
              // backgroundColor:
              //   position === "right" ? "primary.main" : "background.paper",
              // color:
              //   position === "right" ? "primary.contrastText" : "text.primary",
              backgroundColor:
                position === "right" ? "#58d686" : "background.paper",
              color:
                position === "right" ? "#262c48" : "text.primary",
              px: 2,
              py: 1,
            }}
          >
            {getMessageBody()}
          </Card>
        </Box>
      </Stack>
    </Box>
  );
};

export { ChatMessage };
