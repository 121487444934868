import Typography from "@mui/material/Typography";

const ChatMessageBodyLoading = ({ text }) => {
  return (
    <Typography
      color="inherit"
      variant="body1"
      whiteSpace="pre-line"
      sx={{ opacity: 0.5 }}
    >
      Aan het denken...
    </Typography>
  );
};

export { ChatMessageBodyLoading };
