import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "../theme";
import { WidgetContainer } from "./WidgetContainer";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

export const settings = {
  colorPreset: "blue",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "evident",
  paletteMode: "light",
  responsiveFontSizes: true,
  stretch: false,
};

const App = () => {
  const theme = createTheme({
    colorPreset: settings.colorPreset,
    contrast: settings.contrast,
    direction: settings.direction,
    paletteMode: settings.paletteMode,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
          <Routes>
            <Route exact path="/" element={<WidgetContainer />} />
          </Routes>
      </ThemeProvider>
    </Router>
  );
};

export { App };
