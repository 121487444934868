import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import { createOptions } from "./base/create-options";
import { createOptions as createLightOptions } from "./light/create-options";

export const createTheme = (config) => {
  let theme = createMuiTheme(
    createOptions({
      direction: config.direction,
    }),
    createLightOptions({
      colorPreset: config.colorPreset,
      contrast: config.contrast,
    })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
