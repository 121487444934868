import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Scrollbar } from "./ui/Scrollbar";
import { useEffect, useRef, useState } from "react";
import { ChatMessages } from "./ChatMessages";
import { ChatThreadToolbar } from "./ChatThreadToolbar";
import { ChatMessageAdd } from "./ChatMessageAdd";
import {
  getConversation,
  sendMessage,
  startConversation,
  startConversationCar,
  getConversationCar,
  sendMessageCar,
} from "../lib/conversation";
import { ALLOWED_PARAMS, ALLOWED_PARAMS_CAR } from "../constants";

const Chat = ({onClickTopBar}) => {
  const url = new URL(window.location);
  const messagesRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [uuid, setUuid] = useState(url.searchParams.get("uuid"));
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    const init = async () => {
      const params = {};

      url.searchParams.forEach((value, key) => {
        // if (ALLOWED_PARAMS.includes(key)) params[key] = value; Uncommenten voor camera versie
        if (ALLOWED_PARAMS_CAR.includes(key)) params[key] = value;
      });

      //const { uuid, messages } = await startConversation(params); Uncommenten voor camera versie
      const { uuid, messages } = await startConversationCar(params);
      console.log(params);

      setUuid(uuid);
      setMessages(messages);
    };

    const initExistingConversation = async () => {
      const messages = await getConversationCar(uuid);
      setMessages(messages);
    };

    if (uuid) {
      initExistingConversation();
    } else {
      init();
    }
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (!messagesRef.current) return;

      const container = messagesRef.current;
      const scrollElement = container.getScrollElement();

      if (scrollElement) scrollElement.scrollTop = container.el.scrollHeight;
    };

    scrollToBottom();
  }, [messages]);

  const handleSend = async (text, hideMessage) => {
    setIsLoading(true);

    if (!hideMessage) setMessages((prev) => [...prev, { text, isUser: true }]);

    //const response = await sendMessage(uuid, text); Uncommenten voor camera versie
    const response = await sendMessageCar(uuid, text);
    setMessages((prev) => [...prev, ...response]);
    setIsLoading(false);
  };

  console.log(uuid, messages);

  return (
    <Stack
      maxWidth="800px"
      height="700px"
      border="1px solid #F2F4F7"
      backgroundColor="#FAFAFA"
      
      sx={{
        flexGrow: 1,
        overflow: "hidden",
        color:"#FAFAFA",
      }}
    >
      <ChatThreadToolbar onClick={onClickTopBar} />
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Scrollbar ref={messagesRef} sx={{ maxHeight: "100%" }}>
          {uuid && messages ? (
            <ChatMessages
              messages={messages}
              onSend={handleSend}
              isLoading={isLoading}
            />
          ) : (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              paddingTop="64px"
            >
              <CircularProgress />
            </Box>
          )}
        </Scrollbar>
      </Box>
      <Divider />
      <ChatMessageAdd
        onSend={handleSend}
        disabled={!uuid || !messages || isLoading}
      />
    </Stack>
  );
};

export { Chat };
