import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useState } from "react";

const ChatMessageBodyProductInformation = ({ text, options, onSend, position }) => {
  const [disabled, setDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setDisabled(true);
    setSelectedOption(option);
    onSend(option, true);
  };

  return (
    <Stack spacing={2}>
      <Typography color="inherit" variant="body1" whiteSpace="pre-line">
        {text}
      </Typography>

      {options && (
        <Box display="flex" flexWrap="wrap" gap={0.5}>
          {options.map((option) => (
            <Chip
              key={option}
              label={option}
              color={option === selectedOption ? "primary" : "default"}
              sx = {{backgroundColor: option === selectedOption ? '#262c48' : '#58d686'}}
              onClick={() => handleSelect(option)}
              disabled={disabled}
              size="lg"
            />
          ))}
        </Box>
      )}
      {position === "left" && !text.includes("Welkom") && (
         //<Box display="flex" flexWrap="wrap" gap={0.5} justifyContent="flex-end">
         <Box display="flex" flexWrap="wrap" gap={0.5} justifyContent="flex-start">

            {/* <Chip
              variant="outlined"
              style={{
                color: "#262c48",
                //backgroundColor: "#262c48",
                border: 'none', 
                textDecoration: 'underline'
              }}
              label="Leg dit verder uit"
              onClick={() => onSend("Kun je dit verder uitleggen?")}
              size="small"
            ></Chip> */}

            <Chip
              variant="outlined"
              style={{
                color: "#262c48",
                //backgroundColor: "#262c48",
                border: 'none', 
                textDecoration: 'underline'
              }}
              label="Terug naar producten ontdekken"
              onClick={() => onSend("Terug naar producten ontdekken")}
              size="small"
            ></Chip>
          </Box>
      )}
    </Stack>
  );
};

export { ChatMessageBodyProductInformation };
