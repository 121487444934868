export const ENDPOINT = process.env.REACT_APP_API_URL;

export const ALLOWED_PARAMS = ["name", "brand", "category", "store"];
export const ALLOWED_PARAMS_CAR = ["seatAmount", "range", "chargeType", "chargeTime", "chargeTimeFast", "price", "brand", "carroserie"];


export const INITIAL_MESSAGES = [
  {
    type: "text",
    text: "Welkom bij onze camera keuzehulp! Ik help je graag bij het vinden van de perfecte camera voor jouw fotografiebehoeften. Laten we beginnen met een paar vragen om je beter te kunnen adviseren.",
    isUser: false,
  },
  {
    type: "chips",
    identifier: "cameraType",
    text: "Wat is het hoofddoel van het kopen van de camera?",
    options: [
      "Vakantiefoto's",
      "Portretfoto's",
      "Sportfoto's",
      "Stadsfoto's",
      "Studiofoto's",
      "Wildlife foto's",
    ],
    isUser: false,
  },
];


