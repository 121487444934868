import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const ChatThreadToolbar = ({onClick}) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={2}
      //style={{ backgroundColor: "#58d686", cursor: "pointer", borderColor: "#262c48", borderStyle: "solid", borderWidth: 2}}
      style={{ backgroundColor: "#58d686", cursor: "pointer"}}
      onClick={onClick}
      sx={{
        flexShrink: 0,
        minHeight: 44,
        px: 2,
        py: 1,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        <Avatar src="/avatar.png" sx={{ width: 30, height: 30}} />
        <Typography color="#262c48" variant="subtitle2">AI Assistent</Typography>
      </Stack>
    </Stack>
  );
};

export { ChatThreadToolbar };
