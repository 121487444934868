import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ProductCard } from "./ProductCard";
import Chip from "@mui/material/Chip";


const ChatMessageBodyProducts = ({ products, text, options, sendMessage}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentProduct = products[currentIndex];
  const upIndex = () => {
    if (currentIndex < products.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };
  const downIndex = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(products.length - 1);
    }
  };
  return (
    <Box
      className=""
      maxWidth={400}
      display="flex"
      gap="12px"
      flexDirection="column"
    >
      <Typography color="inherit" variant="body1" whiteSpace="pre-line">
        {text}
      </Typography>

      {!currentProduct && (
        <Typography color="inherit" variant="body1" whiteSpace="pre-line">
          Er zijn geen producten gevonden
        </Typography>
      )}
      {currentProduct && (
      <ProductCard product={currentProduct} />
      )}

      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          style={{
            backgroundColor: "#58d686",
          color: "#262c48",
            padding: 0,
            margin: "10px 0",
          }}
          onClick={downIndex}
          type="button"
        >
          <ChevronLeft fill="#fff" />
        </IconButton>
        <Box style={{ display: "flex", marginTop: 16 }}>
          {products.map((product, index) => (
            <div
              key={index}
              style={{
              backgroundColor: "#58d686",
              color: "#262c48",
              width: 10,
              height: 10,
              borderRadius: 100,
              opacity: currentIndex !== index ? 0.3 : 1,
              margin: "0 5px",
            }}
            />
          ))}
        </Box>
        <IconButton
          style={{
            backgroundColor: "#58d686",
            color: "#262c48",
            padding: 0,
            margin: "10px 0",
          }}
          onClick={upIndex}
          type="button"
        >
          <ChevronRight fill="#fff" />
        </IconButton>
        
      </Box>
      {options.map((option, i) => (
          <>
          {/* {option.type === "message" && (
            <Chip
            key={i}
            variant="outlined"
            style={{
              color: "#262c48",
              //backgroundColor: "#262c48",
              border: 'none', 
              textDecoration: 'underline'
            }}
            label="Stel een vraag over de producten"
            onClick={() => sendMessage(option.message)}
            size="small"
            ></Chip>
          )} */}
        </>
      ))}
    </Box>
  );
};

export { ChatMessageBodyProducts };
