import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ProductCard } from "./ProductCard";

const ChatMessageBodyProductAdvice = ({
  product: inputProduct,
  text,
  options,
  sendMessage,
}) => {
  const product = inputProduct[0];

  return (
    <Box className="" maxWidth={400}>
     
      <Typography margin={1} marginLeft={0} marginRight={0} variant="subtitle2" whiteSpace="pre-line">
        {text}
      </Typography>
    
      {product && (
      <ProductCard product={product} />
      )}

      {options.map((option, i) => (
        <>
          {option.type === "link" && (
            <Button
              index={i}
              variant="contained"
              type="button"
              target="_blank"
              style={{
                backgroundColor: "#0067DD",
                color: "#fff",
                width: "100%",
                marginTop: "1em"
              }}
              href={option.link}
            >
              {option.title}
            </Button>
          )}
          {option.type === "message" && (
            <Button
              index={i}
              variant="contained"
              type="button"
              target="_blank"
              style={{
                backgroundColor: "#0067DD",
                color: "#fff",
                width: "100%",
                marginTop: "1em"

              }}
              onClick={() => sendMessage(option.message)}
            >
              {option.title}
            </Button>
          )}
        </>
      ))}
    </Box>
  );
};

export { ChatMessageBodyProductAdvice };
