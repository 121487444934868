import Stack from "@mui/material/Stack";
import { ChatMessage } from "./ChatMessage";

const ChatMessages = ({ messages, isLoading, onSend }) => {
  return (
    <Stack spacing={2} sx={{ p: 3 }} maxHeight="75%">
      {messages.map((message, i) => {
        return (
          <ChatMessage
            {...message}
            key={`message_${i}`}
            position={message.isUser ? "right" : "left"}
            onSend={onSend}
          />
        );
      })}

      {isLoading && <ChatMessage position="left" type="loading" />}
    </Stack>
  );
};

export { ChatMessages };
